import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActivityFeed } from 'components/activity-feed'
import { useStore } from 'store/context'

export type SkillActivityFeedProps = {
  fromDatetime?: string
  skillId: string
  toDatetime?: string
  userId: string
}

export const SkillActivityFeed = observer((props: SkillActivityFeedProps) => {
  const { fromDatetime, skillId, toDatetime, userId } = props

  const { activities, userSkills } = useStore()

  return (
    <div className="pb-6">
      <ActivityFeed
        activities={activities.filteredActivities({
          fromDatetime,
          skillId,
          toDatetime,
          userId,
        })}
        disableLink
        focusSkillIds={userSkills.focusedSkillIdsForUser(userId)}
        hasNextPage={false}
        showAddButton={false}
        showFocusSkillName
      />
    </div>
  )
})
