import { store } from 'store/index'
import { User } from 'store/modules/users'
import { UserSkill } from 'store/modules/user_skills'

export const useSkillContributors = (userSkill?: UserSkill) => {
  if (!userSkill) {
    return
  }

  const user = userSkill.user

  const activities = store.activities.filteredActivities({
    skillId: userSkill.skill.id,
    userId: user.id,
    recordType: [
      'Action',
      'Win',
      'FeedbackItem',
      'FeedbackRequest',
      'Checkin',
      'Note',
    ],
  })

  const activityUsers = activities.map((a) => {
    if (a.recordType === 'Win') {
      return a.record.reporter
    } else if (
      a.recordType === 'FeedbackItem' ||
      a.recordType === 'FeedbackRequest' ||
      a.recordType === 'Action'
    ) {
      return a.creator
    } else if (a.recordType === 'Checkin') {
      if (a.record.assessor?.id !== user.id) return a.record.assessor
      if (a.record.author?.id !== user.id) return a.record.author
    }
  })

  const uniqueActivityUsers = activityUsers.filter(
    (activityUser, index, self) =>
      !!activityUser &&
      user.id !== activityUser.id &&
      index === self.findIndex((u) => u?.id === activityUser.id)
  ) as User[]

  return uniqueActivityUsers
}
