import {
  AvatarButtonGroup,
  GlobalDrawer,
  Tag,
  useDrawerContext,
  useDrawerProps,
} from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { X } from '@phosphor-icons/react'
import * as React from 'react'
import cn from 'classnames'
import { SkillActivityFeed } from './skill-activity-feed'
import { store } from 'store/index'
import { trackEvent } from '../../services/event-tracker'
import { CheckinSkillLevelLabel } from 'components/checkin-skill-level-label'
import { CreateFormModalButton } from 'components/create-form-modal'
import { User } from 'store/modules/users'
import { FocusSkillStar } from 'components/focus-skill-star'
import { SOURCE } from 'pages/growth-profile-page/utils'
import { useSkillContributors } from './use-skill-contributors'
import { avatarGroupProps } from '../../utils/user-helpers'
import { FormattedDateTime } from 'components/formatted-datetime'
import { HtmlContent } from 'components/atoms/editor/html-content'

const getUpdatesDrawerId = (userId: string) => ({
  drawerUserId: userId,
  drawerId: `updates-drawer-${userId}`,
})

type UpdatesDrawerProps = {
  editMode?: boolean
  fromDatetime?: string
  skillIds: string[]
  toDatetime?: string
  userId: string
}

export const UpdatesDrawer = observer((props: UpdatesDrawerProps) => {
  const { editMode, fromDatetime, skillIds, toDatetime, userId } = props

  const user = store.users.byId(userId)

  const fetchActivities = React.useCallback(async () => {
    await store.activities.fetchAllWithAllIncludes(1000, {
      from_datetime: fromDatetime,
      skill_id: skillIds,
      to_datetime: toDatetime,
      user_id: userId,
    })
  }, [fromDatetime, skillIds, toDatetime, userId])

  const fetchUserSkills = React.useCallback(async () => {
    await store.userSkills.fetchForUser(userId)
  }, [userId])

  React.useEffect(() => {
    fetchActivities()
    fetchUserSkills()
  }, [fetchActivities, fetchUserSkills])

  if (!user) return null

  return (
    <BaseUpdatesDrawer
      editMode={editMode}
      fromDatetime={fromDatetime}
      toDatetime={toDatetime}
      user={user}
    />
  )
})

type BaseUpdatesDrawerProps = Omit<
  UpdatesDrawerProps,
  'skillIds' | 'userId'
> & {
  user: User
}

const BaseUpdatesDrawer: React.VFC<BaseUpdatesDrawerProps> = (props) => {
  const { editMode, fromDatetime, toDatetime, user } = props

  const drawerContext = useDrawerContext()
  const { drawerUserId, drawerId } = getUpdatesDrawerId(user.id)

  React.useEffect(() => {
    const main = document.querySelector<HTMLDivElement>(
      '.checkin-drawer-wrapper'
    )

    if (!main || !editMode || window.innerWidth < 1200) return

    const open = drawerContext.activeDrawerId === drawerId
    main.style.width = open ? 'calc(100% - 500px)' : ''
  }, [drawerContext.activeDrawerId, editMode])

  React.useEffect(() => {
    const openDrawer = ((event: CustomEvent) => {
      const eventUserId = event.detail.userId

      if (eventUserId !== drawerUserId) {
        // @todo figure out why 2 drawers are opening when the conditions in FOU-265 are met. This is a band-aid.
        return
      }

      const alreadyOpen =
        drawerContext.getDrawerProps(drawerId).skillId === event.detail.id

      if (alreadyOpen) return drawerContext.closeDrawer()

      drawerContext.openDrawer(drawerId, {
        skillId: event.detail.id,
        skillName: event.detail.name,
        skillDescription: event.detail.description,
        skillRequirementLevel: event.detail.requirementLevel,
        skillCheckinLevel: event.detail.checkinLevel,
        skillCheckinDate: event.detail.checkinDate,
      })

      trackEvent('$track_updates_drawer_opened', {
        source: event.detail.source,
        skillType: event.detail.skillType,
      })
    }) as EventListener

    document.addEventListener('updatesdrawer:open', openDrawer)

    return () => {
      document.removeEventListener('updatesdrawer:open', openDrawer)
    }
  }, [drawerContext])

  const {
    skillId,
    skillName,
    skillDescription,
    skillRequirementLevel,
    skillCheckinLevel,
    skillCheckinDate,
  } = useDrawerProps<{
    skillId: string
    skillName?: string
    skillDescription?: string
    skillRequirementLevel?: number
    skillCheckinLevel?: number
    skillCheckinDate?: Date
  }>(drawerId)

  const closeDrawer = () => {
    drawerContext?.closeDrawer()
  }

  // TODO: Lift this nastiness up the tree
  const userSkill = user
    .sortedUserSkills()
    .find((userSkill) => userSkill.skill.id === skillId)
  const contributors = useSkillContributors(userSkill)

  if (!userSkill) return null

  return (
    <GlobalDrawer
      className="sm:w-[400px] md:w-[500px]"
      id={drawerId}
      overlay={false}
      title="Updates"
    >
      <div
        className={cn(
          'h-full relative z-20 isolate',
          editMode && 'pt-[49.5px] md:pt-[50px]'
        )}
      >
        <div className="flex items-baseline justify-between pt-4 px-6 pb-4 border-0 border-b border-solid border-gray-50">
          <div className="flex items-center gap-1">
            <h2 className="text-base">Progress against {skillName}</h2>
            {user?.isCurrentUser && (
              <FocusSkillStar
                size={14}
                source={SOURCE}
                userSkill={userSkill}
                editable
              />
            )}
          </div>
          <button
            onClick={closeDrawer}
            className="z-10 bg-white rounded-full p-2 w-8 h-8 flex justify-center items-center border-px border-gray-50 border-solid"
          >
            <X className="h-4 text-gray-900 w-4" weight="bold" />
            <span className="sr-only">Close</span>
          </button>
        </div>
        <article className="flex flex-col gap-6 lg:fixed h-screen overflow-auto p-6 w-full">
          <header className="flex flex-col gap-2">
            <hgroup className="flex flex-col gap-1">
              <div className="flex items-center gap-2"></div>
              {skillDescription && (
                <HtmlContent className="text-base">
                  {skillDescription}
                </HtmlContent>
              )}
            </hgroup>
          </header>
          <section className="grid grid-cols-3 gap-y-4">
            {skillRequirementLevel !== undefined && (
              <div role="group" className="contents">
                <span className="font-bold">Current level</span>
                <div className="h-full flex items-center gap-2 col-span-2">
                  <Tag variant="paper">L{skillRequirementLevel}</Tag>
                  {skillCheckinLevel !== undefined && (
                    <CheckinSkillLevelLabel
                      level={skillCheckinLevel}
                      requiredLevel={skillRequirementLevel}
                    />
                  )}
                </div>
              </div>
            )}
            {skillCheckinDate && (
              <div role="group" className="contents">
                <span className="font-bold">Last check-in</span>
                <FormattedDateTime
                  date={skillCheckinDate}
                  className="col-span-2"
                  variant="relative"
                />
              </div>
            )}
            {!!contributors?.length && (
              <div role="group" className="contents">
                <span className="font-bold">Contributors</span>
                <AvatarButtonGroup
                  avatars={avatarGroupProps(contributors, {
                    href: true,
                    stateTooltip: true,
                  })}
                  size="xs"
                  limit={5}
                  limitStyle="avatar"
                />
              </div>
            )}
          </section>
          <section className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <h2 className="font-bold text-sm">Activity</h2>
            </div>
            <SkillActivityFeed
              fromDatetime={fromDatetime}
              skillId={skillId}
              toDatetime={toDatetime}
              userId={user.id}
            />
          </section>
        </article>
      </div>
    </GlobalDrawer>
  )
}
